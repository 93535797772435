import { useRef, useState } from 'react';
import AlertIcon from '@ux/icon/alert';
import CheckmarkIcon from '@ux/icon/checkmark';
import EllipsisIcon from '@ux/icon/ellipsis';
import { Menu, MenuButton, MenuList, MenuItem } from '@ux/menu';
import Text from '@ux/text';
import Tooltip from '@ux/tooltip';
import space, { asOptions } from '@ux/space';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import ChevronUp from '@ux/icon/chevron-up';
import Spinner from '@ux/spinner';
import StarIcon from '@ux/icon/star';
import StarFilledIcon from '@ux/icon/star-filled';
import CartIcon from '@ux/icon/cart';

import {
  CapabilityNamesI8Map,
  CapabilityStatuses,
  CapabilityStatusI8Map,
  getCapabilityHelpMessageId,
  getShortCapabilityStatus,
} from './constants';

/**
 * Component that displays a favourite button with an optional spinner.
 *
 * @param {Object} site - The site object representing the site.
 * @param {Function} toggleFavourite Toggle a site being marked as a favourite/non-favourite.
 *
 * @returns {React.Element} - The rendered component.
 */
const FavouriteButton = ({ site, toggleFavourite }) => {
  const intl = useIntl();

  const [isFavouriting, setIsFavouriting] = useState(false);

  /**
   * Toggles the favourite status of a given site. After the status change,
   * the site is removed from the selected sites.
   *
   * @param {Object} site - The site object whose favourite status needs to be toggled.
   * @param {Function} toggleFavourite Toggle a site being marked as a favourite/non-favourite
   * @returns {Promise<Object>} A promise that resolves to an object indicating the success or failure of the operation.
   * @throws {Error} Throws an error if the operation fails.
   */
  const handleToggleFavourite = async (site, toggleFavourite) => {
    try {
      setIsFavouriting(true);
      await toggleFavourite(site);
      setIsFavouriting(false);

      return {
        success: true,
      };
    } catch (error) {
      setIsFavouriting(false);
      return {
        success: false,
        error: intl.formatMessage({ id: 'mysites__favourite_site_error' }),
      };
    }
  };

  return (
    <>
      {isFavouriting ? (
        <div className="d-flex justify-content-center mr-2">
          <Spinner size="sm" />
        </div>
      ) : (
        <Button
          icon={site?.isFavourite ? <StarFilledIcon /> : <StarIcon />}
          onClick={() => handleToggleFavourite(site, toggleFavourite)}
          id="mysites__favourite_button"
        />
      )}
    </>
  );
};

export default FavouriteButton;

/**
 * CapabilityIcon Element
 *
 * @param {String} helpMessage - The message to display in the tooltip
 * @param {String} actionUrl - The URL to navigate to when the icon is clicked
 * @param {String} status - The status of the capability
 * @param {Boolean} openOnNewTab - Whether to open the actionUrl in a new tab
 * @param {Boolean} entitled - Whether the user is entitled to the capability
 *
 * @returns {JSX.Element} React.component
 */
export const CapabilityIcon = ({
  helpMessage,
  actionUrl = '#',
  status,
  openOnNewTab,
  entitled,
}) => {
  const intl = useIntl();

  const [showToolTip, setShowToolTip] = useState(false);
  const anchorRef = useRef(null);

  const tooltip = (
    <Tooltip
      anchorRef={anchorRef}
      onClose={() => setShowToolTip(false)}
      id="capability-tooltip"
      placement="top"
    >
      <div>{helpMessage}</div>
    </Tooltip>
  );

  const iconStateClass = () => {
    if (!entitled) return 'not-entitled';

    return status?.replaceAll('_', '-').toLowerCase();
  };

  return (
    <a
      href={actionUrl}
      target={openOnNewTab ? '_blank' : '_self'}
      className="capability-link"
      data-testid="capability-link"
    >
      <span
        className={`my-sites-icon-container m-lg-auto has-${iconStateClass()}`}
        data-testid={`capability-icon-${iconStateClass()}`}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
        ref={anchorRef}
      >
        {entitled ? (
          <>
            {[
              CapabilityStatuses.WARNING,
              CapabilityStatuses.NOT_ACTIVE,
              CapabilityStatuses.CRITICAL,
              CapabilityStatuses.NOT_ENTITLED,
            ].includes(status) && <AlertIcon width={24} height={24} />}

            {status === CapabilityStatuses.HEALTHY && (
              <CheckmarkIcon width={24} height={24} />
            )}
          </>
        ) : (
          <CartIcon width={24} height={24} />
        )}
        {showToolTip && tooltip}
        <Text.span as="caption" className="d-lg-none ml-1">
          {intl.formatMessage({
            id: CapabilityStatusI8Map[status] || status,
          })}
        </Text.span>
      </span>
    </a>
  );
};

CapabilityIcon.propTypes = {
  helpMessage: PropTypes.string,
  actionUrl: PropTypes.string,
  status: PropTypes.string,
};

/**
 * CapabilityColumn Element
 s
 * @param {Object} capability - The capability object
 * @param {String} name - The name of the capability
 * @param {Boolean} entitled - Whether the user is entitled to the capability
 *
 * @returns {JSX.Element} React.component
 */
export const CapabilityColumn = ({ capability, name, entitled }) => {
  const intl = useIntl();

  const shortStatus = getShortCapabilityStatus(capability, entitled);

  const openOnNewTab = capability?.actionUrl?.includes('.sucuri.net');

  return (
    <space.div
      as={asOptions.LOOKUP}
      className={`${name}-capability-column d-flex justify-content-between justify-content-lg-center align-items-center py-1 py-lg-4`}
    >
      <div className="d-lg-none">
        <Text.p
          className="mb-0"
          text={intl.formatMessage({
            id: CapabilityNamesI8Map[name.toUpperCase()] || name,
          })}
        />
      </div>

      <CapabilityIcon
        helpMessage={intl.formatMessage({
          id: getCapabilityHelpMessageId({ capability, name, entitled }),
        })}
        actionUrl={capability?.actionUrl}
        status={shortStatus}
        openOnNewTab={openOnNewTab}
        entitled={entitled}
      />
    </space.div>
  );
};

CapabilityColumn.propTypes = {
  capability: PropTypes.object,
  name: PropTypes.string,
  domain: PropTypes.string,
  subscriptions: PropTypes.array,
};

/**
 * SiteActions
 *
 * @param {Function} handleRemoveSite - The function to handle the remove site action
 *
 * @returns {JSX.Element} React.component
 */
export const SiteActions = ({ handleRemoveSite }) => {
  const intl = useIntl();

  return (
    <Menu id="site-menu">
      <MenuButton icon={<EllipsisIcon />} />
      <MenuList placement="bottom-end">
        <MenuItem
          onClick={handleRemoveSite}
          onSelect={handleRemoveSite}
          className="site-action"
        >
          {intl.formatMessage({
            id: 'mysites__remove_site',
          })}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

SiteActions.propTypes = {
  handleRemoveSite: PropTypes.func,
};

/**
 * SitesError Element
 *
 * @param {Function} recheckHandler - The function to handle the recheck action
 *
 * @returns {JSX.Element} React.component
 */
export const SitesError = ({ recheckHandler }) => {
  const intl = useIntl();

  return (
    <space.div
      block
      gap
      as={asOptions.BLOCK}
      className="my-sites-error d-flex flex-column"
    >
      <div className="justify-content-center text-center error">
        <div id="custom-error" className="p-2">
          <AlertIcon width={70} height={70} />
          <Text.h2
            size={1}
            as="title"
            text={intl.formatMessage({
              id: 'mysites__failed_to_load_context',
            })}
          />
          {recheckHandler && (
            <div className="d-flex flex-column justify-content-center mt-4">
              <div className="d-flex justify-content-center">
                <Button
                  design="secondary"
                  onClick={recheckHandler}
                  text={intl.formatMessage({ id: 'mysites__retry_sites' })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </space.div>
  );
};

SitesError.propTypes = {
  recheckHandler: PropTypes.func,
};

/**
 * Sites Table Capability Header Column Element
 *
 * @param {String} siteSort - The current sort order
 * @param {Function} onClick - The function to handle the sort action
 * @param {String} titleId - The title id to display
 * @param {String} className - The class name to apply to div wrapper
 *
 * @returns {JSX.Element} React.component
 */
export const SitesTableHeaderColumn = ({
  siteSort,
  onClick,
  titleId,
  className = 'd-none d-lg-flex text-center',
}) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <button
        onClick={onClick}
        className={`site-th-btn d-flex align-items-center sort-${siteSort}`}
      >
        <Text.h5
          as="title"
          className="mb-0 mr-2"
          size={-2}
          text={intl.formatMessage({ id: titleId })}
        />
        <span className="site-sort-icon d-flex">
          <ChevronUp />
        </span>
      </button>
    </div>
  );
};

SitesTableHeaderColumn.propTypes = {
  siteSort: PropTypes.string,
  onClick: PropTypes.func,
  titleId: PropTypes.string,
};

/**
 * SiteNotFound Element
 * @param {Function} clearFilters - The function to clear the filters
 * @param {String} siteSearch - The site search string
 * @returns {JSX.Element} React.component
 */
export const SiteNotFound = ({ clearFilters, siteSearch }) => {
  const intl = useIntl();

  return (
    <space.div
      block
      gap
      as={asOptions.BLOCK}
      className="my-sites-error d-flex flex-column"
    >
      <div className="justify-content-center text-center error">
        <div className="p-2">
          <AlertIcon width={70} height={70} />
          <Text.h2
            size={1}
            as="title"
            text={intl.formatMessage(
              {
                id: 'mysites__site_not_found',
              },
              {
                site: siteSearch,
              },
            )}
          />
          {clearFilters && (
            <div className="d-flex flex-column justify-content-center mt-4">
              <div className="d-flex justify-content-center">
                <Button
                  design="secondary"
                  onClick={clearFilters}
                  text={intl.formatMessage({ id: 'mysites__clear_filters' })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </space.div>
  );
};

SiteNotFound.propTypes = {
  clearFilters: PropTypes.func,
  siteSearch: PropTypes.string,
};
