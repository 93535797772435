import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useSiteContext,
  deleteSite,
  favouriteSite,
} from '../../wss-context/src';
import { SitesError } from '../lib';
import {
  createSearchSuggestions,
  SortOrder,
  sortSitesByScope,
  sortSitesByFavourites,
} from '../lib/constants';
import MySitesTable from '../components';
import { useURLSearchParams } from '../lib/hooks';
import { getSites } from '../../wss-context/src/query';

/**
 * My Sites Controller
 * @param {Object} props Deconstructed props
 * @param {Array} props.sites Array of user sites
 * @returns {JSX.Element} My Sites
 */
const MySitesController = ({ sites = [] }) => {
  let siteData;
  try {
    // Determine if the context is available before rendering the controller.
    siteData = useSiteContext(sites);
  } catch (error) {
    return (
      <div className="container my-sites-container d-flex justify-content-center py-4">
        <SitesError />
      </div>
    );
  }

  const defaultSortState = {
    domain: SortOrder.DESC,
    monitoring: SortOrder.DESC,
    firewall: SortOrder.DESC,
    ssl: SortOrder.DESC,
    backups: SortOrder.DESC,
  };

  const { query, push } = useURLSearchParams();
  const siteQueryParam = query?.site;

  // Default siteSearch to the siteQueryParam if it exists
  const [siteSearch, setSiteSearch] = useState(siteQueryParam);
  const [siteSort, setSiteSort] = useState(defaultSortState);
  const [sitesList, setSites] = useState(siteData?.data || []);

  const sortedByFavourite = useMemo(
    () => sortSitesByFavourites(siteData.data),
    [siteData.data],
  );

  useEffect(() => {
    setSites(() => sortedByFavourite);
  }, [sortedByFavourite]);

  // On site search change, filter the sites data.
  useEffect(() => {
    // Update the query param with the site search value
    push('site', siteSearch);

    if (!siteData?.data?.length) {
      setSites([]);
      return;
    }

    if (!siteSearch) {
      setSites(sortedByFavourite);
      return;
    }

    setSites(siteData.data.filter((site) => site.domain.includes(siteSearch)));
  }, [siteSearch, siteData?.data]);

  useEffect(() => {
    setSiteSearch(siteQueryParam);
  }, [siteQueryParam]);

  /**
   * Sorts sites by domain or capability scope and updates sites & siteSort state values
   *
   * @param {String} scope The scope to sort by
   * @returns {void}
   */
  const sortSites = (scope) => {
    const scopeSort =
      siteSort[scope] === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    setSiteSort({
      ...defaultSortState,
      [scope]: scopeSort,
    });

    setSites(sortSitesByScope(sitesList, scope, scopeSort));
  };

  /**
   * Removes a site from the user's account
   * @param {*} subscriptionId The subscription ID of the site to remove
   * @returns {Promise<*>} Returns the promise of the request to the API
   */
  const removeSite = async (subscriptionId) => {
    try {
      await deleteSite(subscriptionId);
      window.location.reload();
    } catch (error) {
      throw new Error('Failed to remove site.');
    }
  };

  /**
   * Change the favourite status of a site.
   * @param {Object} site - The site object whose favourite status is to be changed.
   * @returns {Promise<void>} Returns the promise of the request to the API.
   *
   * @throws {Error} Throws an error if the favourite status could not be changed.
   */
  const toggleFavourite = async (site) => {
    try {
      await favouriteSite(site.domain, !site.isFavourite);
      const fetchedSites = await getSites();
      setSites(sortSitesByFavourites(fetchedSites.data.sites));
    } catch (error) {
      throw new Error('Failed to change favourite status.');
    }
  };

  const siteDomainArray = sitesList && sitesList.map((site) => site.domain);
  const siteSuggestion = createSearchSuggestions(siteDomainArray, siteSearch);
  return (
    <>
      <div className="container my-sites-container d-flex flex-column">
        {!siteData.isError && (
          <MySitesTable
            sitesList={sitesList}
            onSortSites={sortSites}
            siteData={siteData}
            siteSort={siteSort}
            siteSearch={siteSearch}
            setSiteSearch={setSiteSearch}
            siteSuggestion={siteSuggestion}
            removeSite={removeSite}
            toggleFavourite={toggleFavourite}
          />
        )}
        {siteData.isError && (
          <SitesError recheckHandler={() => siteData.refetch()} />
        )}
      </div>
    </>
  );
};

MySitesController.propTypes = {
  sites: PropTypes.array,
};

export default MySitesController;
