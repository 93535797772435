import { useRouter } from 'next/router';

/**
 * MySitesSetupController Component
 * @description This component is responsible for redirecting to the setup URL of the selected plan.
 * @param {Object} props - Component props.
 * @param {Array} props.plans - An array of plans.
 * @returns {React.Element} The component.
 */
const MySitesSetupController = ({ plans = [] }) => {
  const router = useRouter();
  const setup = router.query.setup;

  if (!setup) return null;

  const availablePlan = plans.find((p) => p.id === setup);

  if (availablePlan?.setupUrl) {
    window.location.assign(availablePlan.setupUrl);
  }

  return null;
};

export default MySitesSetupController;
