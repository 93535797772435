import { useState } from 'react';
import { useIntl } from 'react-intl';
import { withAuthRequired } from '@godaddy/gasket-auth';
import { withLocaleRequired } from '@gasket/react-intl';
import Text from '@ux/text';
import Button from '@ux/button';
import space, { asOptions } from '@ux/space';
import Alert from '@ux/alert';
import { Recommendations } from '@px/recommendations';
import { format } from 'date-fns';
import Link from 'next/link';

import { SECUI_MY_SITES_V2 } from '@/common/flags';
import { withFlagOrE2sProtected } from '@/components/flags';
import { isSucuriUI } from '@/client/utils/sucuriUICheck';
import { isImpersonation } from '@/common/helpers';

import Head from '../components/head';
import { getPlans } from '../packages/wss-context/src';
import MySitesController from '../packages/my-sites/controller';
import SecurityPlans from '../packages/security-plans/components/index';
import Error from '../components/custom-error';
import { whitelabeledURL } from '../helpers/ApiHelper';
import MySitesSetupController from '../packages/my-sites/controller/setup';

const MySites = (props) => {
  const intl = useIntl();
  const { sites, serverConfig, motd, offline, plans } = props;

  const [recommendationsOpen, openRecommendations] = useState(false);

  return (
    <>
      <Head title={intl.formatMessage({ id: 'common__website_security' })} />
      <space.div
        block
        gap
        as={asOptions.MODULE}
        className="container my-sites-container d-flex flex-column flex-md-row justify-content-md-between align-items-md-center px-2"
      >
        <Text.h1
          as="title"
          size={5}
          text={intl.formatMessage({ id: 'wss_title' })}
          className="mb-0"
        />
        <div className="d-flex justify-content-start justify-content-md-between ml-md-auto">
          <Button
            className="ml-md-3"
            design="secondary"
            href={
              isSucuriUI()
                ? 'https://support.sucuri.net/support/'
                : '/_redirect/support/new'
            }
            text={intl.formatMessage({
              id: 'wss_setup_success_base__request_support',
            })}
          />
          {isSucuriUI() ? (
            <Button
              className="ml-3"
              design="primary"
              target="_blank"
              href={`${serverConfig?.sucuri_account_portal}/settings/billing`}
              text={intl.formatMessage({ id: 'upgrade_plan__button' })}
            />
          ) : (
            <>
              {!offline?.active && (
                <Button
                  className="ml-3"
                  design="primary"
                  onClick={() => openRecommendations(true)}
                  text={intl.formatMessage({ id: 'noplans__button' })}
                />
              )}
            </>
          )}
          {isImpersonation() ? (
            <button className="ml-3 bg-primary text-white">
              <Link
                href="/"
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  fontWeight: 700,
                }}
              >
                My Sites V1
              </Link>
            </button>
          ) : null}
        </div>
      </space.div>

      {motd?.active && !offline?.active && (
        <space.div className="container mb-3">
          <Alert
            id="maintenance-message"
            emphasis="neutral"
            title={intl.formatMessage({ id: 'common__maintenance_notice' })}
          >
            <div className={'mt-3'}>
              {intl.formatMessage(
                {
                  id:
                    motd?.messageId || 'common__maintenance_schedule_datetime',
                },
                {
                  datetime:
                    motd?.start && format(new Date(motd?.start), 'PP p'),
                },
              )}
            </div>
          </Alert>
        </space.div>
      )}

      {!isSucuriUI() && !offline?.active && (
        <>
          <MySitesSetupController plans={plans} />

          <SecurityPlans />

          {recommendationsOpen && (
            <Recommendations
              id="wss-recommendation"
              component="nextSteps"
              placementKey="webSecurityPlans"
              appKey="web-security"
              openInModal={true}
              onPurchaseComplete={(order) => {
                openRecommendations(false);

                if (typeof window !== 'undefined') {
                  window.location.href = whitelabeledURL(
                    `https://setup.godaddy.com/security/${order?.purchaseData.items[0].externalId}?sender=in-app-cart`,
                    // eslint-disable-next-line no-undef
                    __ENVIRONMENT__,
                  );
                }
              }}
            />
          )}
        </>
      )}

      {!offline?.active && <MySitesController sites={sites} />}

      {offline?.active && (
        <Error
          customTitleId="common__maintenance"
          customSubtitleId="common__maintenance_brb"
          showBackToHomeButton={false}
        />
      )}
    </>
  );
};

export async function getServerSideProps({ req, res }) {
  const user = req?.gdAuth?.token;
  const plid = req?.gdAuth?.jwt?.idp?.plid;
  const motd = req?.config?.switchboard?.secui_motd_mode;
  const offline = req?.config?.switchboard?.secui_offline_mode;

  try {
    const plans = await getPlans(user, plid);

    return {
      props: {
        sites: [],
        plans: plans?.data?.subscriptions,
        motd,
        offline,
      },
    };
  } catch (error) {
    const message = error?.response?.data?.message || error.message;
    req
      .log(__filename)
      .error(`Failed to server render plans for My Sites V2 - ${message}`, {
        req,
        res,
      });

    return {
      props: {
        sites: [],
        plans: [],
        motd,
        offline,
      },
    };
  }
}

MySites.propTypes = {};

export default withAuthRequired({ realm: 'idp', initialProps: false })(
  withFlagOrE2sProtected(
    withLocaleRequired('/locales', { initialProps: false })(MySites),
    SECUI_MY_SITES_V2,
  ),
);
