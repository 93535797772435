import React from 'react';
import { useIntl } from 'react-intl';
import Spinner from '@ux/spinner';
import Alert from '@ux/alert';
import dynamic from 'next/dynamic';
import Text from '@ux/text';

const ComponentLoader = dynamic(
  () => import('@partners/ui-loader').then((mod) => mod.ComponentLoader),
  { ssr: false },
);

export const SecurityPlans = () => {
  const intl = useIntl();

  return (
    <>
      <ComponentLoader
        library="@partners/wss-plans-ui"
        version="1"
        loader={
          <div className="d-flex justify-content-center">
            <Spinner size="sm" />
          </div>
        }
        errorFallback={
          <div className="container my-sites-container">
            <Alert emphasis="critical">
              <Text.h2
                text={intl.formatMessage({
                  id: 'mysites__plans_widget_error',
                })}
                size={1}
                className="m-0"
              />
            </Alert>
          </div>
        }
        componentProps={{}}
      />
    </>
  );
};

export default SecurityPlans;
